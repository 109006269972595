import {
    Dialog, DialogSurface, DialogTitle, DialogContent,
    DialogActions, DialogTrigger, DialogBody, Button, Image, makeStyles
} from "@fluentui/react-components";

function ConfirmationDialog(props) {

    const DeleteEvent = () => {
        props.YesEvent();
    }

    return (
        <Dialog
            open={props.isConfirmDialogOpen}
            onOpenChange={(event, data) => {
                props.setIsDeleteDialogOpen(data.open);
            }}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{props.text}</DialogTitle>
                    <DialogContent>
                        {props.msg}
                    </DialogContent>

                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <Button appearance="primary" onClick={DeleteEvent}>Yes</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

export default ConfirmationDialog