import {
    Label,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands
} from "@fluentui/react-components";
import { Tag, TagGroup } from "@fluentui/react-tags";
import axios from "axios";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Base_Url, auth, db } from "./services/firebase";
import { CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, FilterSVG, NodataInfoSVG, RadioSelected, RadioUnSelected } from "./static/svgs";
import userDataService from "./services/userDataService";
import CustomSearchInput2 from "./components/CustomSearchInput2";

// Update columns to match the charges API response
const columns = [
    { columnKey: "Date", label: "Date Range", width: '100px' },
    { columnKey: "TotalSpent", label: "Total Spent", width: '80px' },
    { columnKey: "RemainingSpend", label: "Remaining Spend", width: '80px' },
    { columnKey: "CarryoverSpend", label: "Carryover Spend", width: '80px' },
    { columnKey: "ChargeforPeriod", label: "Charge for Period", width: '80px' },
    { columnKey: "Description", label: "Description", width: '100px' },
];

const invoicecolumns = [
    { columnKey: "isfrom", label: "IsFrom", width: '100px' },
    { columnKey: "total", label: "Total", width: '80px' },
    { columnKey: "invoicesubtotal", label: "Subtotal", width: '80px' },
    { columnKey: "invoiceTax", label: "Invoice Tax", width: '80px' },
    { columnKey: "appliedbalance", label: "Applied balance", width: '100px' },
    { columnKey: "chargeamount", label: "Charge Amount", width: '100px' },
    { columnKey: "status", label: "Status", width: '80px' },
    { columnKey: "description", label: "Description", width: '180px' },
    { columnKey: "created", label: "Date Created", width: '150px' },
    { columnKey: "InvoiceLineItem", label: "Line Item", width: '400px' },
    { columnKey: "OtherInfo", label: "Other Info", width: '300px' },
];

function MinimumSpend() {
    const styles = useStyles();
    const [allCharges, setAllCharges] = useState([]);
    const [finalCalculation, setFinalCalculation] = useState(null);
    const [currentUserId, setUserId] = useState(null); // State to hold Stripe Customer ID
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [visibleTags, setVisibleTags] = useState([]);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dateTagRef = useRef(null);
    const firstTagRef = useRef(null);
    const [dateTags, setDateTags] = useState([]);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [members, setMembers] = useState([
        { id: 1, name: 'John', selected: false },
        { id: 2, name: 'Jane', selected: false },
        { id: 3, name: 'Doe', selected: false }
    ]);
    const [dateArray, setDateArray] = useState([]);
    const [searchMembers, setSearchMembers] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [memberCreatedDate, setMemberCreatedDate] = useState('');
    const [reportData, setReportData] = useState([]);
    const [hasAccountHolder, setHasAccountHolder] = useState(false);
    const [accountHolderData, setAccountHolderData] = useState(null);


    useEffect(() => {
        fetchUserList()
    }, []);

    const fetchUserList = async () => {
        try {
            if (!!user) {
                const usersQuery = await userDataService.getAllUser();
                let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                const transformedArray = allUserList.map(obj => ({
                    id: obj.id,
                    name: `${obj.firstName} ${obj.lastName}`.toLocaleLowerCase(),
                    displayname: `${obj.firstName} ${obj.lastName}`,
                    email: obj.email,
                    selected: false,
                    lastBillingCalculationDate: obj.lastBillingCalculationDate.toDate() || new Date(),
                    createdAt: obj.createdAt,
                    Created: obj.Created ? obj.Created : obj.createdAt,
                    accountHolderId: obj.accountHolderId || ""
                }));
                setMembers(transformedArray)
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchPurchaseData = (startDate, endDate, onSuccess, onError) => {
        let uid = hasAccountHolder && accountHolderData && accountHolderData.id != "" ? accountHolderData.id : selectedMember.id
        const config = {
            method: 'get',
            url: `${Base_Url}/getUserStripeAndCloverPurchaseHistory`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user.accessToken}`,
                'Accept': "application/json"
            },
            params: {
                "userId": uid,
                "startDate": startDate,
                "endDate": endDate
            }
        };
        setIsLoading(true);
        console.log(`Fetching data from ${startDate} to ${endDate}`);
        axios.request(config)
            .then((response) => {
                setIsLoading(false);
                if (response.status && response.data) {
                    onSuccess(response.data);
                } else {
                    console.error("No data returned");
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.error("Error fetching data:", err);
                if (onError) onError(err);
            });
    };

    const processFirstHalfData = (data, secondHalfStartDate, secondHalfEndDate) => {
        const { final, daterange } = data;
        let carryoverSpend = 0;
        let remainingSpend = 0;
        let chargeForPeriod = 0;
        let totalSpent = final.finalTotal;
        let description = '';

        if (totalSpent < 100000) {
            remainingSpend = (100000 - totalSpent) / 100;
            chargeForPeriod = remainingSpend * 100;
            description = `User spent $${totalSpent / 100}. Charged $${chargeForPeriod / 100} to meet the $1000 minimum. This $${chargeForPeriod / 100} goes into the wallet as credit.`;
        } else {
            carryoverSpend = (totalSpent - 100000) / 100;
            description = `User spent $${totalSpent / 100}, exceeding the $1000 minimum by $${carryoverSpend}. This $${carryoverSpend} is carried over to the second period.`;
        }

        const firstHalfData = {
            fromDate: new Date(daterange.fromDate * 1000).toLocaleDateString(),
            toDate: new Date(daterange.toDate * 1000).toLocaleDateString(),
            totalSpent: totalSpent / 100,
            remainingSpend: remainingSpend,
            carryoverSpend: carryoverSpend,
            chargeForPeriod: chargeForPeriod,
            totalCharge: totalSpent / 100 + chargeForPeriod / 100,
            half: 'firstHalf',
            description: description
        };

        setReportData([firstHalfData]);
        fetchSecondHalfData(firstHalfData, secondHalfStartDate, secondHalfEndDate);
    };

    const processSecondHalfData = (data, firstHalfData) => {
        //TODO: In calculation need to remove firsthalf walletbalance data
        const { final, daterange } = data;
        let remainingSpend = 1000;
        let chargeForPeriod = 0;
        let totalSpent = final.finalTotal;
        let carryoverSpend = firstHalfData.carryoverSpend;
        let description = '';

        if (totalSpent > 0) {
            remainingSpend = (100000 - totalSpent) / 100;
        }

        if (carryoverSpend > 0) {
            remainingSpend = Math.max(0, remainingSpend - carryoverSpend);
            if (remainingSpend <= 0) {
                remainingSpend = 0
            }
            chargeForPeriod = remainingSpend * 100;
            description = `User spent $${totalSpent / 100}, carrying over $${carryoverSpend} to the second period. This reduces the required charge for the second period to $${chargeForPeriod / 100}.`;
        } else {
            if (remainingSpend <= 0) {
                remainingSpend = 0
            }
            chargeForPeriod = remainingSpend * 100;
            description = `User spent $${totalSpent / 100}, no carryover. Full $${chargeForPeriod / 100} charged for the second period.`;
        }
        const secondHalfData = {
            fromDate: new Date(daterange.fromDate * 1000).toLocaleDateString(),
            toDate: new Date(daterange.toDate * 1000).toLocaleDateString(),
            totalSpent: totalSpent / 100,
            remainingSpend: remainingSpend,
            carryoverSpend: carryoverSpend,
            chargeForPeriod: chargeForPeriod,
            totalCharge: totalSpent / 100 + chargeForPeriod / 100,
            half: 'secondHalf',
            description: description
        };

        setReportData((prevData) => [...prevData, secondHalfData]);
    };

    const fetchFirstHalfData = (startDate, endDate, secondHalfStartDate, secondHalfEndDate) => {
        fetchPurchaseData(startDate, endDate,
            (data) => processFirstHalfData(data, secondHalfStartDate, secondHalfEndDate),
            (err) => alert("Error fetching First Half data", err)
        );
    };

    const fetchSecondHalfData = (firstHalfData, startDate, endDate) => {
        fetchPurchaseData(startDate, endDate,
            (data) => processSecondHalfData(data, firstHalfData),
            (err) => alert("Error fetching Second Half data", err)
        );
    };

    const toggleSelect = (id) => {
        setMembers((prevMembers) =>
            prevMembers.map((member) =>
                member.id === id
                    ? { ...member, selected: true } // Select the clicked member
                    : { ...member, selected: false } // Deselect all other members
            )
        );
    };
    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        setMembers(prevMembers =>
            prevMembers.map(member =>
                member.id === value ? { ...member, selected: false } : member
            )
        );
        setReportData([])
    };

    async function checkUserHasParent(accountHolderId) {
        try {
            const userDoc = await userDataService.getUser(accountHolderId);
            let parentuser = userDoc.data();
            if (parentuser) {
                setAccountHolderData(parentuser)
                setHasAccountHolder(true)
                const createdAt = parentuser.createdAt.toDate(); // Get the createdAt of the selected member
                const dateRanges = getYearlySixMonthDateRangesGroup(createdAt, 1); // Adjust number of years as needed
                setDateArray(dateRanges)
                let aDate = parentuser.Created ? parentuser.Created.toDate() : parentuser.createdAt.toDate();
                setMemberCreatedDate(aDate)
            } else {
                setHasAccountHolder(false)
            }
        } catch (error) {
            setHasAccountHolder(false)
        }
    }

    const handleApplyMember = () => {
        // Update visible tags
        const selectedMembers = members.filter(member => member.selected);
        const updatedTags = selectedMembers.map(member => ({
            value: member.id,
            children: member.displayname
        }));
        setVisibleTags(updatedTags);
        // Close the popup
        setPopupVisible(false);
        
        if (selectedMembers[0].accountHolderId && selectedMembers[0].accountHolderId != "") {
            checkUserHasParent(selectedMembers[0].accountHolderId)
        } else {
            setHasAccountHolder(false);
            setAccountHolderData(null);
            const createdAt = selectedMembers[0].createdAt.toDate(); // Get the createdAt of the selected member
            const dateRanges = getYearlySixMonthDateRangesGroup(createdAt, 1); // Adjust number of years as needed
            setDateArray(dateRanges)
            let aDate = selectedMembers[0].Created.toDate()
            setMemberCreatedDate(aDate)
        }
        setReportData([])
        setSelectedMember(selectedMembers[0])
    };
    const handleClear = () => {
        setSearchTerm('');
    };

    const handleSearchMember = event => {
        const value = event.target.value;
        setSearchMembers(value);
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisibleDate(false);
        setPopupVisible(true);
        setPopupVisibleExport(false)
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(true)
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };
    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };
    function onGenerateExportClick(from) {
        const dataArr = allCharges;
        if (dataArr.length > 0) {
            if (from == 2) {
            } else {
                //generateExelData(dataArr, exportSelectedOption, [])
            }
        }
    }

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };
    const handleCancel = () => {
        // Close the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };
    const handleDateApply = () => {
        setReportData([])
        console.log("selectedOption->", selectedOption)

        // Extract start and end dates for both halves
        let firstHalfStartDate = null;
        let firstHalfEndDate = null;
        let secondHalfStartDate = null;
        let secondHalfEndDate = null;

        if (selectedOption) {
            // Extract the dates for the first half
            firstHalfStartDate = selectedOption.children.firstHalf.start;
            firstHalfEndDate = selectedOption.children.firstHalf.end;

            // Extract the dates for the second half
            secondHalfStartDate = selectedOption.children.secondHalf.start;
            secondHalfEndDate = selectedOption.children.secondHalf.end;
        }

        if (selectedMember) {
            // Fetch the report data for both halves
            fetchFirstHalfData(firstHalfStartDate, firstHalfEndDate, secondHalfStartDate, secondHalfEndDate);
            setPopupVisible(false);
            setPopupVisibleDate(false);
        }
    }


    const handleOptionClick = (group) => {
        // If the same group is clicked, it will be deselected
        setSelectedOption((prevOption) =>
            prevOption === group ? null : group
        );
    };
    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={styles.breakFlex}>
                    {/* <Button shape="circular" appearance="primary" onClick={() => navigate(-1)}>Back</Button> */}
                </div>
                <div className={"d-flex justify-space-between"} style={{ marginTop: '10px' }}>
                    <div className={"d-flex"}>
                        <div style={{ marginTop: '8px' }}>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>Customer name</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>6-Month Period</span>
                            <DownArowSVG />
                        </div>
                    </div>

                    <div className={"d-flex"}>
                        {/* <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div> */}
                    </div>
                </div>

                <div style={{ marginTop: '10px' }}>
                    {dateTags.length !== 0 && (
                        <TagGroup role="list" aria-label="Dismiss example">
                            {dateTags.map((tag, index) => (
                                <Tag
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? dateTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>
                <div style={{ marginTop: '10px' }}>

                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example">
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                    <div style={{ marginLeft: '4px' }}>
                        Created Date: {memberCreatedDate ? memberCreatedDate.toLocaleDateString() : '-'}
                    </div>
                </div>
                {
                    accountHolderData && (
                        <Label style={{marginLeft: '4px'}}>
                            Primary Account: {accountHolderData.firstName} {accountHolderData.lastName}
                        </Label>
                    )
                }
                <div className="table-layout3">
                    <Table aria-label="Table with cell actions"> {/* Set min-width here */}
                        <TableHeader className="list-row2">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey} style={{ width: column.width }}> {/* Set width here */}
                                        <span className="listHeaderText">{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {reportData.length > 0 && (
                            <TableBody>
                                {reportData.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TableCellLayout>{data.fromDate} - {data.toDate}</TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>{`$${parseFloat(data.totalSpent).toFixed(2)}`}</TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>{`$${data.remainingSpend.toFixed(2)}`}</TableCellLayout> {/* Display Remaining Spend */}
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>{`$${data.carryoverSpend}`}</TableCellLayout> {/* Carryover Spend */}
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>{`$${data.chargeForPeriod.toFixed(2) / 100}`}</TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>{`${data.description}`}</TableCellLayout>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {
                            isLoading || reportData.length > 0 ? <div /> :
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Report Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please select user then date, to view the data.
                                    </div>
                                </div>
                        }
                    </Table>

                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                </div>
                {popupVisible && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <CustomSearchInput2
                            value={searchMembers}
                            onChange={handleSearchMember}
                            onClick={handleClear}
                        />
                        <div className="popupMemberList">
                            {members
                                .filter(member => member.name.toLowerCase().includes(searchMembers))
                                .map((member) => (
                                    <div key={member.id}>
                                        <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                            <div
                                                onClick={() => toggleSelect(member.id)}
                                                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                            >
                                                {member.selected ? (
                                                    <RadioSelected />  // Your checked SVG
                                                ) : (
                                                    <RadioUnSelected />  // Your unchecked SVG
                                                )}
                                                <div style={{ marginLeft: '8px' }}>{member.displayname}</div>
                                            </div>
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            {/* <button className="borderButton" onClick={handleCancelMemberFilter}>Cancel</button> */}
                            <button className="fillButton" onClick={handleApplyMember}>APPLY</button>
                        </div>
                    </div>
                )}

                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y + 10, left: popupPosition.x - 50, height: undefined }}>
                        {dateArray.map((tag, index) => {
                            // Check if this group (both halves) is selected
                            const isSelected = selectedOption === tag;

                            return (
                                <div
                                    key={tag.value}
                                    className={`todayBtn${isSelected ? " selected" : ""}`}
                                    onClick={() => handleOptionClick(tag)} // Handle group click
                                >
                                    {/* Display Grouping label */}
                                    <div>{`Grouping ${index + 1}`}</div>

                                    {/* Display first half date range */}
                                    <div>{`${formatDate(tag.children.firstHalf.start)} - ${formatDate(tag.children.firstHalf.end)}`}</div>

                                    {/* Display second half date range */}
                                    <div>{`${formatDate(tag.children.secondHalf.start)} - ${formatDate(tag.children.secondHalf.end)}`}</div>
                                </div>
                            );
                        })}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancel}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default MinimumSpend;

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },
    popupmain: {
        width: '256px',
        height: '280px',
        position: 'relative',
        top: '124px',
        borderRadius: '10px',
        border: '1px solid #F4F4F4',
        background: '#FFF',
        boxShadow: '1px 1px 4px 0px rgba(89, 89, 89, 0.25)',
        padding: '16px',
        overflowY: 'auto',
    },
    popupMemberList: {
        paddingTop: '10px',
        maxHeight: '266px',
        overflowY: 'auto'
    },
    totalAmount: {
        marginTop: "16px",
        fontWeight: "bold"
    }
});



const styles = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};
const convertToAmount = (amount) => {
    return amount ? (amount / 100).toFixed(2) : null
}
const LineItemList = ({ lineItems }) => {
    if (lineItems && lineItems.length > 0) {
        return (
            <div>
                {lineItems.map((item, index) => (
                    <div key={index}>
                        <div style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{item.name}</div>
                                <div style={styles.productName2}>${item.amount}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return <div>-</div>;
    }
};

//TODO issue when pass numGroups 2 need to check it shoud start from second year
function getYearlySixMonthDateRangesGroup(createdAt, numGroups = 1) {
    const createdDate = new Date(createdAt); // Parse the createdAt to Date object
    const dateRanges = [];

    // Loop through the number of 6-month intervals you need
    for (let group = 0; group < numGroups; group++) {
        // Calculate the start and end for the first 6-month period of the group
        const firstHalfStart = new Date(createdDate);
        firstHalfStart.setMonth(createdDate.getMonth() + group * 6); // Move forward by group * 6 months
        const firstHalfEnd = new Date(firstHalfStart);
        firstHalfEnd.setMonth(firstHalfStart.getMonth() + 6); // First 6 months of the group

        // Calculate the start and end for the second 6-month period of the group
        const secondHalfStart = new Date(firstHalfEnd);
        secondHalfStart.setDate(firstHalfEnd.getDate() + 1); // One day after the first half ends
        const secondHalfEnd = new Date(secondHalfStart);
        secondHalfEnd.setMonth(secondHalfStart.getMonth() + 6); // Next 6 months after the second half starts

        // Add the date ranges to the dateRanges array as "Groupings"
        dateRanges.push(
            {
                value: `Grouping_${group + 1}`, // Label for grouping (Grouping 1, Grouping 2, etc.)
                children: {
                    firstHalf: { start: firstHalfStart, end: firstHalfEnd },
                    secondHalf: { start: secondHalfStart, end: secondHalfEnd }
                }
            }
        );
    }

    return dateRanges;
}