import {
    Button,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import "./Event.css";
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import EventForm from "./components/Forms/EventForm";
import ImageDialog from "./components/ImageDialog";
import Layout from "./components/Layout";
import RSVPDialog from "./components/RSVPDialog";
import EventDataService from "./services/eventDataService";
import { analytics, auth, db, trackEventAnalytics, trackException, userAnalytics } from "./services/firebase";
import { PlusSVG } from "./static/svgs";
import userDataService from "./services/userDataService";
import EventRSVPForm from "./components/Forms/EventRSVPForm";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const columns = [
    { columnKey: "name", label: "Name" },
    { columnKey: "startDate", label: "Start Date" },
    { columnKey: "endDate", label: "End Date" },
    { columnKey: "price", label: "Price" },
    { columnKey: "maximumAttendees", label: "Maximum Attendees" },
    { columnKey: "minimumAttendees", label: "Minimum Attendees" },
    { columnKey: "address", label: "Address" },
    { columnKey: "images", label: "Images" },
    { columnKey: "rsvps", label: "RSVPS" },
];

function Event() {
    const styles = useStyles();
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [allevents, setAllEvents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEvents, setFilteredEvents] = useState(allevents);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const [open, setOpen] = React.useState(false);
    const [openRSVP, setOpenRSVP] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [allusers, setAllUsers] = useState([]);
    const [selectedRsvpMember, setSelectedRsvpMember] = useState(null);
    const [isRSVPFormOpen, setIsRSVPFormOpen] = useState(false);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        setIsLoading(true);
        fetchUserNameAndEvents();
        userAnalytics(user)
        fetchUserLilst()
    }, [user, loading]);

    useEffect(() => {
        if (page > 1) {
            loadMoreEvents();
        }
    }, [page]);

    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedEvent(selectedItem);
    }

    const openFormNew = () => {
        setIsFormOpen(true);
        let r = (Math.random() + 1).toString(36).substring(7);
        setSelectedEvent(r);
    }

    const openRSVPForm = (selectedItem) => {
        if (selectedItem) {
            setIsRSVPFormOpen(true);
            setSelectedRsvpMember(selectedItem);
        } else {
            setIsRSVPFormOpen(true);
            let r = (Math.random() + 1).toString(36).substring(7);
            setSelectedRsvpMember(r);
        }
    }

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedEvent(selectedItem);
    }

    const fetchUserLilst = async () => {
        try {
            const usersQuery = await userDataService.getAllUser();
            const sortedUsers = usersQuery.docs
                .map((doc) => ({ ...doc.data(), id: doc.id }))
                .sort((a, b) => a.firstName.localeCompare(b.firstName));

            setAllUsers(sortedUsers);
            //setAllUsers(usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

        } catch (error) {
        }
    }

    const fetchUserNameAndEvents = async () => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    //const eventQuery = await EventDataService.getAllEvent();
                    //eventQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    const { events, lastVisible } = await EventDataService.getAllEventsWithPage(pageSize, null);
                    // Update the startAfterDoc for the next page
                    setStartAfterDoc(lastVisible);
                    let allEventArr = events
                    setAllEvents(allEventArr);
                    setFilteredEvents(allEventArr)
                    if (allEventArr.length < pageSize) {
                        setHasMoreData(false)
                    }
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
                setIsLoading(false)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            setIsLoading(false)
            alert("An error occured while fetching user data");
        }
    };

    const loadMoreEvents = async () => {
        const { events, lastVisible } = await EventDataService.getAllEventsWithPage(pageSize, startAfterDoc);
        // Update the startAfterDoc for the next page
        setStartAfterDoc(lastVisible);
        if (events.length < pageSize) {
            setHasMoreData(false)
            if (events.length > 0) {
                setAllEvents(prevEvents => [...prevEvents, ...events]);
                setFilteredEvents(prevEvents => [...prevEvents, ...events]);
            }
            // You can handle this case, e.g., display a message
        } else {
            // Append the new events to the existing events
            setAllEvents(prevEvents => [...prevEvents, ...events]);
            setFilteredEvents(prevEvents => [...prevEvents, ...events]);
        }
    };

    const DeleteEvent = async () => {
        EventDataService.deleteEvent(selectedEvent.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndEvents();
    };


    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allevents.filter(event =>
            event.name.toLowerCase().includes(lowerSearch)
        );
        setFilteredEvents(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openFormNew()}>{'New Event'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredEvents.length > 0 && filteredEvents.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.name}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.startDate).format('Do MMMM YYYY hh mm A')}</TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.endDate).format('Do MMMM YYYY hh mm A')}</TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        {
                                            item?.price && item?.price > 0 ?
                                                <div className={"priceBox"}>{`$` + item.price}</div> :
                                                <div className={"freeBox"}>Free</div>
                                        }
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{item.maximumAttendees}</TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{item.minimumAttendees}</TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{item.address.city}
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                    <TableCell>
                                        <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                            setOpen(true);
                                            setSelectedEvent(item);
                                        }}>Images</Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                            setOpenRSVP(true);
                                            setSelectedEvent(item);
                                        }}>RSVPS</Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
            </div>
            <EventForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedEvent={selectedEvent}
                updateGrid={fetchUserNameAndEvents}>
            </EventForm>
            <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedEvent && typeof (selectedEvent) == "object" ? selectedEvent : undefined}></ImageDialog>
            <RSVPDialog open={openRSVP} setOpen={setOpenRSVP} selectedEvent={selectedEvent && typeof (selectedEvent) == "object" ? selectedEvent : undefined} onEditRsvpClick={(rsvp) => {
                setOpenRSVP(false);
                openRSVPForm(rsvp)
            }}>
            </RSVPDialog>
            <EventRSVPForm isOpen={isRSVPFormOpen} setIsOpen={(val) => setIsRSVPFormOpen(val)} selectedRsvpMember={selectedRsvpMember}
                users={allusers} selectedEvent={selectedEvent && typeof (selectedEvent) == "object" ? selectedEvent : undefined}
                updateGrid={fetchUserNameAndEvents}>
            </EventRSVPForm>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteEvent} text={'Event'} />
        </Layout>
    );
}
export default Event;