import { useEffect, useState } from "react";
import { Button } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

export default function SubsriptionListActive(props) {
    const [activesubscriptions, setActiveSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.activesubscriptions) {
            setActiveSubscriptions(props.activesubscriptions)
        } else {
            setActiveSubscriptions([])
        }
        setLoading(false)
    }, [props.activesubscriptions]);

    const handleClick = (sub) => {
        props.onCancelSubsriptionClick(sub)
    };

    return (
        <div style={{ padding: "0px", marginBottom: '10px' }}>
            {loading ? (
                <p style={{ textAlign: "center" }}>Loading...</p>
            ) : activesubscriptions.length > 0 ? (
                <div style={{ display: "grid", gap: "8px" }}>
                    {activesubscriptions.map((sub, index) => (
                        <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "4px", border: "1px solid #ccc", borderRadius: "4px", backgroundColor: "#f9f9f9" }}>
                            <span style={{ marginLeft: '12px', fontSize: "14px", fontWeight: "500" }}>{sub.productName}</span>
                            <Button appearance="transparent" icon={<Dismiss24Regular />} onClick={()=> {handleClick(sub)}} />
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ textAlign: "center", color: "gray" }}>No active subscriptions found.</p>
            )}
        </div>
    );
}
