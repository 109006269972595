import {
    Button,
    Label,
    Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import moment from 'moment';
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import VehiclesRequestForm from "./components/Forms/VehiclesRequestForm";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import rentalLocationDataService from "./services/rentalLocationDataService";
import UserDataService from "./services/userDataService";
import VehicleDataService from "./services/vehicleDataService";
import vehiclesRequestDataService from "./services/vehiclesRequestDataService";
import { PlusSVG } from "./static/svgs";
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "Vehicle", label: "Vehicle" },
    { columnKey: "Member", label: "Member" },
    // { columnKey: "CurrentMileage", label: "Current Mileage" },
    { columnKey: "warehouseBayNumber", label: "Bay Number" },
    { columnKey: "createdAt", label: "Created Date" },
];

let fobPageNum = 1;
function VehiclesRequest() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [allvehicles, setAllVehicles] = useState([]);

    const [allVehiclesRequest, setAllVehiclesRequest] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredVehiclesRequest, seetFilteredVehiclesRequest] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [alllocations, setAllLocations] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedVehicle(selectedItem);
    }

    const openFormNew = () => {
        setIsFormOpen(true);
        let r = (Math.random() + 1).toString(36).substring(7);
        setSelectedVehicle(r);
    }

    const fetchUserNameAndVehicles = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const vehiclesQuery = await VehicleDataService.getAllVehicle();
                    let vehicleArr = vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllVehicles([...vehicleArr]);

                    const vehiclesReqQuery = await vehiclesRequestDataService.getAllVehiclesRequest();
                    let vehicleReqArr = vehiclesReqQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllVehiclesRequest(vehicleReqArr)
                    seetFilteredVehiclesRequest(vehicleReqArr)

                    const usersQuery = await UserDataService.getAllUser();
                    let allUserArr = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    allUserArr.sort((a, b) => {
                        const lastNameA = a.lastName || ''; // If lastName is null or undefined, treat it as empty string
                        const lastNameB = b.lastName || ''; // If lastName is null or undefined, treat it as empty string
                        return lastNameA.localeCompare(lastNameB); // Compare the last names alphabetically
                    });
                    setAllUsers(allUserArr);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchAllLocations = async () => {
        try {
            const vehiclesQuery = await rentalLocationDataService.getAllRentalLocation();
            setAllLocations(vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            console.error(error);
            alert("An error occured while fetching location data");
            trackException(JSON.stringify(error))
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndVehicles();
        fetchAllLocations();
    }, [user, loading]);


    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedVehicle(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteVehicle = async () => {
        vehiclesRequestDataService.deleteVehiclesRequest(selectedVehicle.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndVehicles();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allVehiclesRequest.filter(event => {
            return (
                event?.vehicle?.year.toLowerCase().includes(lowerSearch) ||
                event?.vehicle?.model.toLowerCase().includes(lowerSearch) ||
                event?.vehicle?.make.toLowerCase().includes(lowerSearch)
            )
        });
        seetFilteredVehiclesRequest(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openFormNew()}>{'NEW vehicle Request'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredVehiclesRequest.length > 0 && filteredVehiclesRequest.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {/* <Label title={item.model}>{item.model}</Label> */}
                                            {item?.vehicle?.make} {item?.vehicle?.model} {item?.vehicle?.year}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>{allUsers.filter(t => t.id == item.memberId)[0]?.firstName} &nbsp;
                                                {allUsers.filter(t => t.id == item.memberId)[0]?.lastName}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    {/* <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>{item?.vehicle?.currentMileage}</Label>
                                        </TableCellLayout>
                                    </TableCell> */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>{item?.vehicle?.warehouseBayNumber}</Label>
                                        </TableCellLayout>

                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>
                                            {moment(item.createdAt.toDate()).format('Do MMMM YYYY hh mm A')}
                                        </TableCellLayout>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <VehiclesRequestForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)}
                vehicles={allvehicles}
                selectedVehicleReq={selectedVehicle}
                updateGrid={fetchUserNameAndVehicles} users={allUsers}></VehiclesRequestForm>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteVehicle} text={'VehiclesRequest'} />
        </Layout>
    )
}

export default VehiclesRequest;