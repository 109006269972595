import {
    Button,
    Label,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import axios from "axios";
import { Tag, TagGroup } from "@fluentui/react-tags";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Base_Url, auth, db } from "./services/firebase";
import { CalanderSVG, CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, FilterSVG, NodataInfoSVG, RadioSelected, RadioUnSelected } from "./static/svgs";
import orderHistoryDataService from "./services/orderHistoryDataService";
import { getChargeCardAmount, getMinimumspend, getPointspend } from "./components/OrderHistoryUtil";
import { BookingCategory } from "./Helper";
import { generateExelData } from "./components/ReportUserPurchaseUtil";
import userDataService from "./services/userDataService";

// Update columns to match the charges API response
const columns = [
    { columnKey: "isfrom", label: "IsFrom", width: '100px' },
    { columnKey: "total", label: "Total", width: '80px' },
    { columnKey: "invoicesubtotal", label: "Subtotal", width: '80px' },
    { columnKey: "invoiceTax", label: "Invoice Tax", width: '80px' },
    { columnKey: "appliedbalance", label: "Applied balance", width: '100px' },
    { columnKey: "chargeamount", label: "Charge Amount", width: '100px' },
    { columnKey: "status", label: "Status", width: '80px' },
    { columnKey: "description", label: "Description", width: '180px' },
    { columnKey: "created", label: "Date Created", width: '150px' },
    { columnKey: "InvoiceLineItem", label: "Line Item", width: '400px' },
    { columnKey: "OtherInfo", label: "Other Info", width: '300px' },
];

function MemberPurchaseDetails() {
    const styles = useStyles();
    const [allCharges, setAllCharges] = useState([]);
    const [filteredCharges, setFilteredCharges] = useState([]); // New state for filtered charges
    const [currentUserId, setUserId] = useState(null); // State to hold Stripe Customer ID
    const [memberId, setMemberId] = useState(null);
    const [memberEmail, setMemberEmail] = useState(null);
    const [parentUserId, setParentUserId] = useState(null); // State to hold Stripe Customer ID
    const [parentMemberEmail, setParentMemberEmail] = useState(null);
    const [hasAccountHolder, setHasAccountHolder] = useState(false);
    const [accountHolderData, setAccountHolderData] = useState(null);
    const [selectedIncludeSubsription, setSelectedIncludeSubsription] = useState(true);

    const [totalAmount, setTotalAmount] = useState(0); // State for total amount
    const navigate = useNavigate();
    const { state } = useLocation();
    const [user, loading, error] = useAuthState(auth);
    const [visibleTags, setVisibleTags] = useState([]);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dateTagRef = useRef(null);
    const [dateTags, setDateTags] = useState([]);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    useEffect(() => {
    }, []);

    useEffect(() => {
        if (state && state?.email) {
            console.log("--->", state?.email);
            fetchUserDetails();
        } else {
            navigate("/");
        }
    }, [state, navigate]);

    // Fetch charges whenever the currentUserId or date range changes
    useEffect(() => {
        // Set the start date to one month ago
        const startDate = moment().subtract(1, 'months'); // One month ago
        const endDate = moment(); // Today
        // Set the selected dates to state
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate); // End date is today
        if (currentUserId) {
        }
    }, [currentUserId]);


    useEffect(() => {
        if(selectedIncludeSubsription){
            setFilteredCharges(allCharges)
        } else {
            //TODO: Apple filter
            const productIds = STRIEP_PRODUCT_IDS.map(p => p.id);
            const productNames = STRIEP_PRODUCT_IDS.map(p => p.name.toLowerCase());
            const filteredTransactions = allCharges.filter(transaction => {
                // Remove if billing reason is one of the excluded types
                if (["subscription_create", "subscription_cycle"].includes(transaction.billing_reason)) {
                    return false;
                }
                // If there are no line items, keep the transaction
                if (!transaction.line_items || transaction.line_items.length === 0) return true;
                // Check if any line item matches product ID or product name
                const hasMatchingLineItem = transaction.line_items.some(item =>
                    productIds.includes(item.product) ||
                    productNames.includes(item.name?.toLowerCase())
                );
                return !hasMatchingLineItem; // Exclude matching transactions
            });
            setFilteredCharges(filteredTransactions)
        }
    }, [selectedIncludeSubsription]);

    const fetchUserDetails = async () => {
        try {
            const userquery = query(collection(db, "Users"), where("email", "==", state?.email));
            const userdoc = await getDocs(userquery);
            if (userdoc.docs.length > 0) {
                const userData = userdoc.docs[0].data(); // Get the first document
                setMemberEmail(userData.email || '')
                setMemberId(userData.memberNumber || '')
                setUserId(userData.id); // Set the customer ID from user data
                checkUserHasParent(userData);
            } else {
                console.log('No access');
            }
        } catch (err) {
            console.error(err);
            alert("An error occurred while fetching user data");
        }
    };

    async function checkUserHasParent(userdata) {
        if (userdata && userdata.accountHolderId && userdata.accountHolderId != "" && userdata.accountHolderId != userdata.id) {
            try {
                const userDoc = await userDataService.getUser(userdata.accountHolderId);
                let parentuser = userDoc.data();
                if (parentuser) {
                    setAccountHolderData(parentuser)
                    setParentMemberEmail(parentuser.email || '')
                    setParentUserId(parentuser.id)
                    setHasAccountHolder(true)
                } else {
                    setHasAccountHolder(false)
                }
            } catch (error) {
                setHasAccountHolder(false)
            }
        } else {
            setHasAccountHolder(false)
        }
    }


    const convertToAmount = (amount) => {
        return amount ? (amount / 100).toFixed(2) : null
    }
    const fetchCharges = (startDate, endDate) => {
        if (!currentUserId) {
            console.log('No Stripe customer ID found.');
            return;
        }
        let uid = hasAccountHolder && parentUserId != "" ? parentUserId : currentUserId
        setIsLoading(true)
        const config = {
            method: 'get',
            url: `${Base_Url}/getUserStripePurchaseHistory`,
            // url: `http://127.0.0.1:5001/warehouse-prod-40386/us-central1/getUserStripePurchaseHistory`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${user.accessToken}`,
                'Accept': "application/json"
            },
            params: {
                userId: uid,
                startDate: startDate,
                endDate: endDate
            }
        };

        axios.request(config)
            .then((response) => {
                setIsLoading(false)
                if (response.status && response.data && response.data.transactions) {
                    const formattedTransactions = (response.data.transactions || []).map((transaction) => {
                        let invoice = transaction.invoice || {};
                        let charge = transaction.charge || {};
                        let createdDt = invoice.created
                            ? new Date(invoice.created * 1000).toLocaleString()
                            : charge.created
                                ? new Date(charge.created * 1000).toLocaleString()
                                : '';
                        return {
                            total: invoice && invoice.total ? convertToAmount(invoice.total) : convertToAmount(charge.amount) || 0,
                            invoiceSubtotal: invoice && invoice.subtotal ? convertToAmount(invoice.subtotal) : 0,
                            invoiceTax: invoice && invoice.tax ? convertToAmount(invoice.tax) : 0,
                            appliedBalance: invoice && invoice.pre_payment_credit_notes_amount ? convertToAmount(invoice.pre_payment_credit_notes_amount) : 0,
                            startingBalance: invoice && invoice.starting_balance ? convertToAmount(invoice.starting_balance) : 0,
                            chargeAmount: convertToAmount(charge.amount) || 0,
                            status: invoice && invoice.status ? invoice.status : charge.status || '',
                            created: createdDt, // Convert timestamp to human-readable date
                            description: invoice?.description || charge?.description || '',
                            isFrom: 'Stripe',
                            billing_reason: invoice.billing_reason,
                            line_items: invoice.line_items
                                ? invoice.line_items.map((item) => ({
                                    name: item.description, // Use description as item name
                                    qty: item.quantity, // Quantity
                                    amount: (item.amount / 100).toFixed(2), // Convert amount to dollars
                                    product: item.product
                                }))
                                : [], // Fallback to empty array if no line_items
                            metadata: invoice.metadata || null,
                        };
                    });

                    formattedTransactions.sort((a, b) => {
                        const dateA = new Date(a.created).getTime();
                        const dateB = new Date(b.created).getTime();
                        return dateA - dateB; // For ascending order (oldest first)
                    });
                    //setAllCharges(formattedTransactions)
                    fetchCloverDataFromAPI(startDate, endDate, formattedTransactions)
                } else {
                    setAllCharges([])
                    setFilteredCharges([])
                }
            })
            .catch((err) => {
                setIsLoading(false)
                console.error(err);
                alert("Error fetching charge data.", err);
            });
    }
    //url: `${Config.API_BASE_URL}/getCloverOrderListByMember?fromDate=${formattedFromDate}&toDate=${toDate}&memberId=${memberId}`,
    const fetchCloverDataFromAPI = async (startDate, endDate, chargeArray) => {
        setIsLoading(true);
        const startDatetimestamp = new Date(startDate).getTime();
        const endDatetimestamp = new Date(endDate).getTime();
        try {
            const config = {
                method: 'get',
                url: `${Base_Url}/getCloverOrdersByDateRangeFIR`,
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Test`,
                    Accept: "application/json",
                },
                params: {
                    fromDate: startDatetimestamp,
                    toDate: endDatetimestamp,
                    memberId: memberId,
                    limit: 100
                }
            };
            const response = await axios.request(config);
            if (response.status === 200 && response.data) {
                const { data } = response.data;
                const formattedTransactions = data.map((transaction) => {
                    return {
                        total: (transaction.total / 100).toFixed(2),
                        invoiceSubtotal: (transaction.total / 100).toFixed(2),
                        invoiceTax: 0,
                        appliedBalance: 0,
                        chargeAmount: (transaction.total / 100).toFixed(2),
                        status: transaction.paymentState,
                        created: new Date(transaction.createdTime).toLocaleString(), // Convert timestamp to human-readable date
                        description: transaction.note,
                        isFrom: 'Clover',
                        line_items: transaction.lineItems?.elements ? transaction.lineItems.elements.map((item) => {
                            return {
                                name: item.name, // Use the name field from each item
                                qty: 1, // Quantity is not available, assuming 1 as default
                                amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                            };
                        }) : []
                    };
                });
                const mergedArray = [...chargeArray, ...formattedTransactions];
                // Sort merged array based on the `created` timestamp (ascending order)
                mergedArray.sort((a, b) => {
                    const dateA = new Date(a.created).getTime();
                    const dateB = new Date(b.created).getTime();
                    return dateA - dateB; // For ascending order (oldest first)
                });
                // Set merged and sorted data into state
                setAllCharges(mergedArray);
                setFilteredCharges(mergedArray)
                fetchFilterProductOrderHistory(mergedArray)
            }
        } catch (err) {
            console.error(err);
            //alert("An error occurred while fetching payment intents.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFilterProductOrderHistory = async (chargeArray) => {
        try {
            if (!!user) {
                setIsLoading(true);
                let selectedMemberEmails = [memberEmail]
                if (hasAccountHolder && parentMemberEmail != "") {
                    selectedMemberEmails.push(parentMemberEmail);
                }
                const endOf2024 = new Date('2024-12-31T23:59:59.999Z');
                // Adjust start date if it is after December 31, 2024
                let adjustedStartDate = new Date(selectedStartDate);
                if (adjustedStartDate > endOf2024) {
                    adjustedStartDate = endOf2024;
                    return;
                }
                // Adjust end date if it is after December 31, 2024
                let adjustedEndDate = new Date(selectedEndDate);
                if (adjustedEndDate > endOf2024) {
                    adjustedEndDate = endOf2024;
                    return
                }
                const { orderHistory, lastVisible } = await orderHistoryDataService.getAllOrderHistoryBySelection(selectedOption, selectedStartDate, selectedEndDate, selectedMemberEmails)
                const filteredOrderHistory = orderHistory.filter(order => {
                    if (order.metadata && order.metadata.otherInfo) {
                        try {
                            const otherInfo = JSON.parse(order.metadata.otherInfo);
                            return otherInfo.minimumspend !== undefined || otherInfo.drivingfeetbalance !== undefined;
                        } catch (e) {
                            console.error("Invalid JSON in otherInfo:", order.metadata.otherInfo);
                            return false;
                        }
                    }
                    return false;
                });
                // Process the filtered data into the required format

                const formattedTransactions = filteredOrderHistory
                    .map(transaction => {
                        // Handle "Paid_Event_RSVP"
                        if (transaction.metadata?.isPaymentFrom === "Paid_Event_RSVP") {
                            try {
                                const eventInfo = JSON.parse(transaction.metadata.eventInfo); // Parse eventInfo
                                const lineItems = eventInfo.map(item => ({
                                    name: item.name, // Use name field from eventInfo
                                    qty: 1, // Default quantity is 1
                                    amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                                }));
                                return {
                                    total: (transaction.amount / 100).toFixed(2),
                                    invoiceSubtotal: (transaction.amount / 100).toFixed(2),
                                    invoiceTax: 0,
                                    appliedBalance: (transaction.amount / 100).toFixed(2),
                                    chargeAmount: 0,
                                    status: transaction.status,
                                    created: new Date(transaction.createdAt.seconds * 1000).toLocaleString(), // Convert timestamp to human-readable date
                                    description: transaction.description,
                                    isFrom: 'Minimumspend',
                                    line_items: lineItems, // Use formatted line items
                                    metadata: transaction.metadata
                                };
                            } catch (e) {
                                console.error("Invalid JSON in eventInfo:", transaction.metadata.eventInfo);
                                return null; // Skip if eventInfo is invalid
                            }
                        }
                        // Handle "Purchase_Product"
                        else if (transaction.metadata?.isPaymentFrom === "Purchase_Product") {
                            try {
                                const productInfo = JSON.parse(transaction.metadata.productInfo); // Parse productInfo
                                const lineItems = productInfo.map(item => ({
                                    name: item.id, // Use id field as name (or adjust if product names are available elsewhere)
                                    qty: item.quantity, // Use the quantity field from productInfo
                                    amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                                }));
                                return {
                                    total: (transaction.amount / 100).toFixed(2),
                                    invoiceSubtotal: (transaction.amount / 100).toFixed(2),
                                    invoiceTax: 0,
                                    appliedBalance: 0,
                                    chargeAmount: (transaction.amount / 100).toFixed(2),
                                    status: transaction.status,
                                    created: new Date(transaction.createdAt.seconds * 1000).toLocaleString(), // Convert timestamp to human-readable date
                                    description: transaction.description,
                                    isFrom: transaction.metadata.isPaymentFrom,
                                    line_items: lineItems, // Use formatted line items
                                    metadata: transaction.metadata
                                };
                            } catch (e) {
                                console.error("Invalid JSON in productInfo:", transaction.metadata.productInfo);
                                return null; // Skip if productInfo is invalid
                            }
                        }
                        else if (transaction.metadata?.isPaymentFrom === "Vehicle_Booking") {
                            try {
                                const rentalInfo = JSON.parse(transaction.metadata.rentalInfo); // Parse rentalInfo
                                const lineItems = rentalInfo.map(item => ({
                                    name: item.name, // Use name field from rentalInfo
                                    qty: 1, // Default quantity is 1
                                    amount: (item.price / 100).toFixed(2) // Convert price from cents to dollars
                                }));
                                return {
                                    total: (transaction.amount / 100).toFixed(2),
                                    invoiceSubtotal: (transaction.amount / 100).toFixed(2),
                                    invoiceTax: 0,
                                    appliedBalance: 0,
                                    chargeAmount: (transaction.amount / 100).toFixed(2),
                                    status: transaction.status,
                                    created: new Date(transaction.createdAt.seconds * 1000).toLocaleString(), // Convert timestamp to human-readable date
                                    description: transaction.description,
                                    isFrom: transaction.metadata.isPaymentFrom,
                                    line_items: lineItems, // Use formatted line items
                                    metadata: transaction.metadata
                                };
                            } catch (e) {
                                console.error("Invalid JSON in rentalInfo:", transaction.metadata.rentalInfo);
                                return null; // Skip if rentalInfo is invalid
                            }
                        }
                        return null; // Skip if isPaymentFrom is not recognized
                    })
                    .filter(item => item !== null); // Remove null values

                const mergedArray2 = [...chargeArray, ...formattedTransactions];
                setAllCharges(mergedArray2);
                setFilteredCharges(mergedArray2);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        // Set the position of the popup relative to the button
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);

    };


    const handleCancelDateFilter = () => {
        // Close the popup
        setSelectedOption(null)
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        let startDate = null;
        let endDate = null;
        if (selectedOption == "CustomDate") {
            setAllCharges([])
            setFilteredCharges([])
            if (selectedStartDate && selectedEndDate) {
                setSelectedOption("CustomDate");
                const formattedStartDate = selectedStartDate.format('YYYY-MM-DD'); // Use Moment's format
                const formattedEndDate = selectedEndDate.format('YYYY-MM-DD'); // Use Moment's format
                fetchCharges(formattedStartDate, formattedEndDate);
                let tagStr = `${formattedStartDate} - ${formattedEndDate}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        } else {
            setAllCharges([])
            setFilteredCharges([])
            const today = new Date();

            switch (selectedOption) {
                case "Today":
                    startDate = moment(today).startOf('day').format('YYYY-MM-DD HH:mm'); // Start of today at 12:00 AM
                    endDate = moment(today).endOf('day').format('YYYY-MM-DD HH:mm'); // End of today at 11:59 PM
                    break;

                case "Yesterday":
                    const yesterday = moment(today).subtract(1, 'day');
                    startDate = yesterday.startOf('day').format('YYYY-MM-DD HH:mm'); // Start of yesterday at 12:00 AM
                    endDate = yesterday.endOf('day').format('YYYY-MM-DD HH:mm'); // End of yesterday at 11:59 PM
                    break;

                case "This month":
                    startDate = moment(today).startOf('month').format('YYYY-MM-DD'); // Start of current month
                    endDate = moment(today).endOf('month').format('YYYY-MM-DD'); // End of current month
                    break;

                case "Past month":
                    const pastMonth = moment(today).subtract(1, 'month');
                    startDate = pastMonth.startOf('month').format('YYYY-MM-DD'); // Start of past month
                    endDate = pastMonth.endOf('month').format('YYYY-MM-DD'); // End of past month
                    break;

                default:
                    startDate = null;
                    endDate = null;
                    break;
            }
            if (startDate && endDate) {
                fetchCharges(startDate, endDate);
                let startDateOnly = startDate.split(' ')[0];
                let endDateOnly = endDate.split(' ')[0];
                let tagStr = `${startDateOnly} - ${endDateOnly}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        }
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        //        setSelectedStartDate(date);
        setSelectedStartDate(moment(date)); // Ensure it's a Moment object
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        //setSelectedEndDate(date);
        setSelectedEndDate(moment(date)); // Ensure it's a Moment object
    };

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };
    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };
    function onGenerateExportClick(from) {
        const dataArr = allCharges;
        if (dataArr.length > 0) {
            if (from == 2) {
            } else {
                generateExelData(dataArr, exportSelectedOption, [])
            }
        }
    }

    const toggleSelect = () => {
        setSelectedIncludeSubsription(prev => !prev);
    };

    function renderEventsOtherItem(item) {
        return (
            <div style={{}}>
                {
                    getChargeCardAmount(item) > 0 ?
                        <div style={styles.productName}>Credit Card ${item.chargeAmount}</div> :
                        getMinimumspend(item) > 0 ?
                            <div style={styles.productName}>Use Minimum ${getMinimumspend(item)}</div> : <div />
                }
            </div>
        )
    }


    function renderRentalOtherItem(item) {
        return (
            <div style={{}}>
                {
                    getChargeCardAmount(item) > 0 ?
                        <div style={styles.productName}>Credit Card: ${getChargeCardAmount(item)}</div>
                        : <div />
                }
                {
                    getMinimumspend(item) > 0 ?
                        <div style={styles.productName}>Minimum: ${getMinimumspend(item)}</div>
                        : <div />
                }
                {
                    getPointspend(item) > 0 ?
                        <div style={styles.productName}>Driving Fleet: ${getPointspend(item)}</div>
                        : <div />
                }
            </div>
        )
    }

    return (
        <Layout>
            <div className={styles.content}>
                <div className={styles.breakFlex}>
                    <Button shape="circular" appearance="primary" onClick={() => navigate(-1)}>Back</Button>
                </div>
                <div className={"d-flex justify-space-between"} style={{ marginTop: '10px' }}>
                    <div className={"d-flex"}>
                        <div style={{ marginTop: '8px' }}>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Order Date</span>
                            <DownArowSVG />
                        </div>
                        <div style={{ marginTop: '8px', marginLeft: '12px' }}>
                            <label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                <div
                                    onClick={toggleSelect}
                                    style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                >
                                    {selectedIncludeSubsription ? <CheckBoxChecked /> : <CheckBoxUnChecked />}
                                    <div style={{ marginLeft: '8px' }}>Include Subscriptions</div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <span>Generate report</span>
                            <DownSmallArrow />
                        </div>
                    </div>
                </div>
                {state && state.firstName && state.lastName && (
                    <div className={styles.userDetails}>
                        <p><strong>Name:</strong> {state.firstName} {state.lastName}</p>
                    </div>
                )}
                {
                    accountHolderData && (
                        <Label>
                            Primary Account: {accountHolderData.firstName} {accountHolderData.lastName}
                        </Label>
                    )
                }
                <div style={{ marginTop: '10px' }}>
                    {dateTags.length !== 0 && (
                        <TagGroup role="list" aria-label="Dismiss example">
                            {dateTags.map((tag, index) => (
                                <Tag
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? dateTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>
                {/* Display Total Amount */}
                {/* <div className={styles.totalAmount}>
                    <p>Total: ${totalAmount}</p>
                </div> */}

                <div className="table-layout3">
                    <Table aria-label="Table with cell actions"> {/* Set min-width here */}
                        <TableHeader className="list-row2">
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey} style={{ width: column.width }}> {/* Set width here */}
                                        <span className="listHeaderText">{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {filteredCharges.length > 0 && (
                            <TableBody>
                                {filteredCharges.map((invoice) => (
                                    <TableRow key={invoice.created}>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.isFrom || ''}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.total || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.invoiceSubtotal || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.invoiceTax || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.appliedBalance ? invoice.appliedBalance : Math.abs(invoice.startingBalance) || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.chargeAmount || 0}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>{invoice.status.toUpperCase() || ''}</TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.description || ''}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <Label>{invoice.created || ''}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell >
                                            <TableCellLayout>
                                                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                    <LineItemList lineItems={invoice.line_items} />
                                                </div>
                                            </TableCellLayout>
                                        </TableCell>

                                        <TableCell >
                                            <TableCellLayout>
                                                {
                                                    (invoice.metadata && invoice.metadata.isPaymentFrom && invoice.metadata.isPaymentFrom == BookingCategory.Paid_Event_RSVP) ?
                                                        renderEventsOtherItem(invoice)
                                                        :
                                                        (invoice.metadata && invoice.metadata.isPaymentFrom && invoice.metadata.isPaymentFrom == BookingCategory.Vehicle_Booking) ?
                                                            renderRentalOtherItem(invoice)
                                                            :
                                                            <div />
                                                }
                                            </TableCellLayout>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                        {
                            isLoading || allCharges.length > 0 ? <div /> :
                                // <TableBody>
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Report Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please date range, then click 'GENERATE REPORT' to view, export, and print the data.
                                    </div>
                                </div>
                            // </TableBody>
                        }
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                </div>

                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}
                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y + 10, left: popupPosition.x - 50 }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '47%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    //selected={selectedStartDate}
                                    selected={selectedStartDate ? selectedStartDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div style={{ marginLeft: '3px', marginRight: '3px', marginTop: '16px', width: '18px', height: '1px', backgroundColor: '#C1C1C1' }}></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '47%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    //selected={selectedEndDate}
                                    selected={selectedEndDate ? selectedEndDate.toDate() : null} // Convert Moment to JS Date
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelDateFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default MemberPurchaseDetails;

const LineItemList = ({ lineItems }) => {
    if (lineItems && lineItems.length > 0) {
        // Calculate the total price of all line items
        return (
            <div>
                {lineItems.map((item, index) => (
                    <div key={index}>
                        <div style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{item.name}</div>
                                <div style={styles.productName2}>${item.amount}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return <div>-</div>;
    }
};

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },
    popupmain: {
        width: '256px',
        height: '280px',
        position: 'relative',
        top: '124px',
        borderRadius: '10px',
        border: '1px solid #F4F4F4',
        background: '#FFF',
        boxShadow: '1px 1px 4px 0px rgba(89, 89, 89, 0.25)',
        padding: '16px',
        overflowY: 'auto',
    },
    popupMemberList: {
        paddingTop: '10px',
        maxHeight: '266px',
        overflowY: 'auto'
    },
    totalAmount: {
        marginTop: "16px",
        fontWeight: "bold"
    }
});



const styles = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};
const STRIEP_PRODUCT_IDS = [
    { id: "prod_RKfpm2KnVrI4tv", name: "WAREHOUSE Premium Space" },
    { id: "prod_Qk4iTl4IqoaFA9", name: "Additional Spending Q3" },
    { id: "prod_QfucdU3I8Sslf4", name: "Hoonigan/ Off-Site Space Over-Sized" },
    { id: "prod_QfYOvilJdxrkyC", name: "Hoonigan/Off Site Space" },
    { id: "prod_QKtlpYqKf9UZrH", name: "Additional Spending Q2" },
    { id: "prod_PrF5hMji9SUeu5", name: "Additional Spending Q1" },
    { id: "prod_PK70z1i3EImU4o", name: "WAREHOUSE Moto Space" },
    { id: "prod_PK52OVhtBkxL5H", name: "Quarterly WAREHOUSE Minimum Spend" },
    { id: "prod_PK51C0g2ZL74yV", name: "Monthly WAREHOUSE Lift Space" },
    { id: "prod_PK50AzzeojHE25", name: "Monthly WAREHOUSE Ground Space" },
    { id: "prod_PK4ycLcdFwHzy4", name: "Monthly WAREHOUSE Premium Space" },
    { id: "prod_PJwnF2gDsTVcID", name: "Monthly WAREHOUSE Membership Dues" },
    { id: "prod_P7Bd95w46errmU", name: "Initial WAREHOUSE Moto Space" },
    { id: "prod_P6sKIbHxUDjQxs", name: "Initial WAREHOUSE Premium Space" },
    { id: "prod_P6sIQDXFsVevr0", name: "Initial WAREHOUSE Ground Space" },
    { id: "prod_P6sHY40V2Xj8a5", name: "Initial WAREHOUSE Lift Space" },
    { id: "prod_P6sGmIaX8cEdPi", name: "Initial WAREHOUSE Membership Dues (December)" },
    { id: "prod_P6UyOnapjJRoEo", name: "WAREHOUSE Premium Space" },
    { id: "prod_P6Un3astZerNNV", name: "WAREHOUSE Ground Space" },
    { id: "prod_P6Un96DCFX37LK", name: "WAREHOUSE Lift Space" },
    { id: "prod_P6Um8Hz8U7BTkd", name: "WAREHOUSE Membership Dues" },
    { id: "prod_LhIhsDUWx1I8ob", name: "Warehouse Rental Subscription" }
];