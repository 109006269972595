import {
    Button,
    Label,
    Spinner,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Tag, TagGroup } from "@fluentui/react-tags";
import axios from "axios";
import 'jspdf-autotable';
import moment from 'moment';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Base_Url, auth, trackException } from "./services/firebase";
//import ImageDialog from "./components/ImageDialog";
import debounce from 'lodash/debounce';
import { BookingCategory } from "./Helper";
import './ProductOrderHistory.css';
import { LineItemList } from "./components/CloverLineItemList";
import CustomSearchInput2 from "./components/CustomSearchInput2";
import { checkIfFromCardReaderPos, generateExelData, getChargeCardAmount, getGratuity, getMinimumspend, getPointspend, getSaleTax, getTotal } from "./components/OrderHistoryUtil";
import { convertStripeAmount } from "./components/ReportCloverOrderUtil";
import orderHistoryDataService from "./services/orderHistoryDataService";
import userDataService from "./services/userDataService";
import { CalanderSVG, CheckBoxChecked, CheckBoxUnChecked, DownArowSVG, DownSmallArrow, DownloadSVG, FilterSVG, NodataInfoSVG, PrintIconSVG, RadioSelected, RadioUnSelected } from "./static/svgs";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "id", label: "Id", width: '15%' },
    { columnKey: "orderDate", label: "Order Date", width: '14%' },
    { columnKey: "orderBy", label: "Order By", width: '15%' },
    { columnKey: "product", label: "Product", width: '24%' },
    { columnKey: "pay", label: "Pay", width: '15%' },
    { columnKey: "total", label: "Total", width: '10%' },
];

const comparisonDate = new Date('2024-08-20T00:00:00Z');

function ProductOrderHistory() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [allOrderHistory, setAllOrderHistory] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrderHistory, setFilteredOrderHistory] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(40); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupVisibleDate, setPopupVisibleDate] = useState(false);
    const [popupVisibleExport, setPopupVisibleExport] = useState(false);
    const [members, setMembers] = useState([
        { id: 1, name: 'John', selected: false },
        { id: 2, name: 'Jane', selected: false },
        { id: 3, name: 'Doe', selected: false }
    ]);
    const [searchMembers, setSearchMembers] = useState('');
    const [visibleTags, setVisibleTags] = useState([]);
    const firstTagRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [exportSelectedOption, setExportSelectedOption] = useState(null);
    const [dateTags, setDateTags] = useState([]);
    const navigate = useNavigate();
    const dateTagRef = useRef(null);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserList()
        //fetchProductOrderHistory();
    }, [user, loading]);

    useEffect(() => {
        if (visibleTags && visibleTags.length > 0) {
            const selectedMembers = members.filter(member => member.selected);
            const selectedMemberEmails = selectedMembers.map(member => member.email);
            if (selectedOption) {
                fetchFilterProductOrderHistory(selectedOption)
            } else {
                //fetchProductOrderHistoryByUser(selectedMemberEmails)
            }
        } else {
            if (selectedOption) {
                fetchFilterProductOrderHistory(selectedOption)
            }
        }
    }, [visibleTags]);


    const fetchProductOrderHistoryByUser = async (emailIds) => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(false)
        try {
            if (!!user) {
                const { orderHistory, lastVisible } = await orderHistoryDataService.getOrderHistoryByUserIdWithPage(emailIds, null, null);
                let allEventArr = orderHistory
                if (allEventArr.length < pageSize) {
                    setHasMoreData(false)
                }
                setFilteredOrderHistory(allEventArr)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchFilterProductOrderHistory = async (selection) => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                setIsLoading(true);
                let selectedMemberEmails = []
                if (visibleTags && visibleTags.length > 0) {
                    const selectedMembers = members.filter(member => member.selected);
                    selectedMemberEmails = selectedMembers.map(member => member.email);
                }
                const { orderHistory, lastVisible } = await orderHistoryDataService.getAllOrderHistoryBySelection(selection, selectedStartDate, selectedEndDate, selectedMemberEmails)
                let allEventArr = orderHistory
                setFilteredOrderHistory(allEventArr)
                handleDateApplyClover(allEventArr)
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false)
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchDataFromAPI = async (startDate, endDate, historyArr) => {
        setIsLoading(true);
        const startDatetimestamp = new Date(startDate).getTime();
        const endDatetimestamp = new Date(endDate).getTime();
        try {
            const allPaymentIntents = [];
            const config = {
                method: 'get',
                url: `${Base_Url}/getCloverOrdersByDateRangeFIR`,
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Test`,
                    Accept: "application/json",
                },
                params: {
                    fromDate: startDatetimestamp,
                    toDate: endDatetimestamp,
                    limit: 200
                }
            };
            const response = await axios.request(config);
            if (response.status === 200 && response.data) {
                const { data } = response.data;
                if (data?.length) {

                    let selectedMemberEmails = []
                    if (visibleTags && visibleTags.length > 0) {
                        const selectedMembers = members.filter(member => member.selected);
                        selectedMemberEmails = selectedMembers.map(member => member.email);
                    }
                    // Filter the data array if selectedMemberEmails is not empty
                    let filteredData = data;
                    if (selectedMemberEmails.length > 0) {
                        filteredData = data.filter(item => selectedMemberEmails.includes(item?.user?.email));
                    }
                    const result = mergeAndSort(historyArr, filteredData);
                    setFilteredOrderHistory(result)
                }
            }
        } catch (err) {
            console.error(err);
            //alert("An error occurred while fetching payment intents.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUserList = async () => {
        try {
            if (!!user) {
                const usersQuery = await userDataService.getAllUser();
                let allUserList = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setAllUsers(allUserList);
                const transformedArray = allUserList.map(obj => ({
                    id: obj.id,
                    name: `${obj.firstName} ${obj.lastName}`,
                    email: obj.email,
                    selected: false
                }));
                setMembers(transformedArray)
            }
        } catch (err) {
            console.error(err);
        }
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allOrderHistory.filter(event =>
            event.id.toLowerCase().includes(lowerSearch)
        );
        setFilteredOrderHistory([]);
        setFilteredOrderHistory(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    const handleSearchMember = event => {
        const value = event.target.value;
        setSearchMembers(value);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(prevOption => prevOption === option ? null : option);
    };

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const removeItem = (e, { value }) => {
        setVisibleTags([...visibleTags].filter((tag) => tag.value !== value));
        setMembers(prevMembers =>
            prevMembers.map(member =>
                member.id === value ? { ...member, selected: false } : member
            )
        );
    };

    const openPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(true);
        setPopupVisibleDate(false);
        setPopupVisibleExport(false)
    };

    const openDatePopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left + (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);

        // Set the position of the popup relative to the button
        const popupWidth = 315;
        const popupHeight = 356;
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment

        // Show the popup
        setPopupVisible(false);
        setPopupVisibleExport(false)
        setPopupVisibleDate(true);
    };
    const openExportPopup = (event) => {
        // Calculate the position of the button
        const buttonRect = event.target.getBoundingClientRect();
        const buttonX = buttonRect.left - 100 //+ (buttonRect.width / 2);
        const buttonY = buttonRect.top + (buttonRect.height / 2);
        setPopupPosition({ x: buttonX, y: buttonY + 10 }); // Adjust 10px downward for better alignment
        // Show the popup
        setPopupVisible(false);
        setPopupVisibleDate(false);
        setPopupVisibleExport(true)
    };

    // Function to handle checkbox toggle
    const toggleSelect = (id) => {
        setMembers((prevMembers) =>
            prevMembers.map((member) =>
                member.id === id ? { ...member, selected: !member.selected } : member
            )
        );
    };

    const handleApplyMember = () => {
        const selectedMembers = members.filter(member => member.selected);
        // Update visible tags
        const updatedTags = selectedMembers.map(member => ({
            value: member.id,
            children: member.name
        }));
        setVisibleTags(updatedTags);
        // Close the popup
        setPopupVisible(false);
    };

    const handleCancelMember = () => {
        setPopupVisible(false);
        setPopupVisibleDate(false);
    };

    const handleCancelDateFilter = () => {
        // Close the popup
        setSelectedOption(null)
        setPopupVisible(false);
        setPopupVisibleDate(false);
        //fetchProductOrderHistory();
    };

    const handleCancelExport = () => {
        // Close the popup
        setPopupVisibleExport(false)
    };

    const handleDateApply = () => {
        fetchFilterProductOrderHistory(selectedOption)
        setPopupVisible(false);
        setPopupVisibleDate(false);
    }

    const handleDateApplyClover = (hostoryArr) => {
        let startDate = null;
        let endDate = null;
        if (selectedOption == "CustomDate") {
            if (selectedStartDate && selectedEndDate) {
                setSelectedOption("CustomDate");
                const formattedStartDate = selectedStartDate.toISOString().split('T')[0]; // Use Moment's format
                const formattedEndDate = selectedEndDate.toISOString().split('T')[0]; // Use Moment's format
                fetchDataFromAPI(formattedStartDate, formattedEndDate, hostoryArr);
            }
        } else {
            const today = new Date();
            switch (selectedOption) {
                case "Today":
                    startDate = moment(today).startOf('day').format('YYYY-MM-DD HH:mm'); // Start of today at 12:00 AM
                    endDate = moment(today).endOf('day').format('YYYY-MM-DD HH:mm'); // End of today at 11:59 PM
                    break;
                case "Yesterday":
                    const yesterday = moment(today).subtract(1, 'day');
                    startDate = yesterday.startOf('day').format('YYYY-MM-DD HH:mm'); // Start of yesterday at 12:00 AM
                    endDate = yesterday.endOf('day').format('YYYY-MM-DD HH:mm'); // End of yesterday at 11:59 PM
                    break;
                case "This month":
                    startDate = moment(today).startOf('month').format('YYYY-MM-DD'); // Start of current month
                    endDate = moment(today).endOf('month').format('YYYY-MM-DD'); // End of current month
                    break;
                case "Past month":
                    const pastMonth = moment(today).subtract(1, 'month');
                    startDate = pastMonth.startOf('month').format('YYYY-MM-DD'); // Start of past month
                    endDate = pastMonth.endOf('month').format('YYYY-MM-DD'); // End of past month
                    break;
                default:
                    startDate = null;
                    endDate = null;
                    break;
            }
            if (startDate && endDate) {
                fetchDataFromAPI(startDate, endDate, hostoryArr);
                let startDateOnly = startDate.split(' ')[0];
                let endDateOnly = endDate.split(' ')[0];
                let tagStr = `${startDateOnly} - ${endDateOnly}`
                setDateTags([{ value: tagStr, children: tagStr }])
            }
        }
    }

    const handleDateSelect = (date) => {
        setSelectedOption("CustomDate")
        setSelectedStartDate(date);
    };
    const handleDateSelect2 = (date) => {
        setSelectedOption("CustomDate")
        setSelectedEndDate(date);
    };

    const handleExportChange = (option) => {
        setExportSelectedOption(option);
    };

    function onGenerateExportClick(from) {
        const dataArr = filteredOrderHistory;
        if (from == 2) {
            generateExelData(dataArr, 'Print', allUsers)
        } else {
            generateExelData(dataArr, exportSelectedOption, allUsers)
            setPopupVisibleExport(false)
        }
    }

    function renderPosItem(item) {
        const productInfo = JSON.parse(item.metadata.productInfo);
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        {item.id}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt.toDateString()}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {/* <Label title={item.email}>{item.email}</Label> */}
                        {
                            item.guestName && item.guestName != "" ?
                                <Label>{item.guestName}(Guest)</Label> :
                                <Label>{allUsers.filter(t => t.email == item.email)[0]?.firstName} &nbsp;
                                    {allUsers.filter(t => t.email == item.email)[0]?.lastName}</Label>
                        }
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <ProductList products={item?.products} productInfo={productInfo} />
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{}}>
                            <div style={styles.productName}>Gratuity: ${getGratuity(item)}</div>
                            <div style={styles.productName}>SaleTax: ${getSaleTax(item)}</div>
                            {
                                checkIfFromCardReaderPos(item) == true ?
                                    <div style={styles.productName}>Card Reader POS</div> :
                                    getChargeCardAmount(item) > 0 ?
                                        <div style={styles.productName}>Credit Card: ${getChargeCardAmount(item)}</div> :
                                        getMinimumspend(item) > 0 ?
                                            <div style={styles.productName}>Use Minimum: ${getMinimumspend(item)}</div> : <div />
                            }
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            intentCreatedAt < comparisonDate ?
                                <div>${(item.amount / 100).toFixed(2)}</div> :
                                <div>${getTotal(item)}</div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    function renderEventsItem(item) {
        const enventData = JSON.parse(item?.metadata?.eventInfo);
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        {item.id}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt.toDateString()}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {/* <Label>{allUsers.filter(t => t.email == item.email)[0]?.firstName} &nbsp;
                            {allUsers.filter(t => t.email == item.email)[0]?.lastName}</Label> */}
                        <div style={{ lineBreak: 'anywhere' }}>
                            <Label>{item?.email}</Label>
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={styles.productName}>{BookingCategory.Paid_Event_RSVP}</div>
                        <EventInfo eventInfo={enventData} />
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{}}>
                            {
                                getChargeCardAmount(item) > 0 ?
                                    <div style={styles.productName}>Credit Card ${(item.amount / 100).toFixed(2)}</div> :
                                    getMinimumspend(item) > 0 ?
                                        <div style={styles.productName}>Use Minimum ${getMinimumspend(item)}</div> : <div />
                            }
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            <div>${(item.amount / 100).toFixed(2)}</div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    function renderRentalItem(item) {
        const intentCreatedAt = new Date(item.intentCreatedAt * 1000);
        return (
            <TableRow key={item.id}>
                <TableCell className={"table-row-fonts__primary"}>
                    <TableCellLayout>
                        {item.id}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {intentCreatedAt.toDateString()}
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {/* <Label>{allUsers.filter(t => t.email == item.email)[0]?.firstName} &nbsp;
                            {allUsers.filter(t => t.email == item.email)[0]?.lastName}</Label> */}
                        <div style={{ lineBreak: 'anywhere' }}>
                            <Label>{item?.email}</Label>
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={styles.productName}>{BookingCategory.Vehicle_Booking}</div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <div style={{}}>
                            {
                                getChargeCardAmount(item) > 0 ?
                                    <div style={styles.productName}>Credit Card: ${getChargeCardAmount(item)}</div>
                                    : <div />
                            }
                            {
                                getMinimumspend(item) > 0 ?
                                    <div style={styles.productName}>Minimum: ${getMinimumspend(item)}</div>
                                    : <div />
                            }
                            {
                                getPointspend(item) > 0 ?
                                    <div style={styles.productName}>Driving Fleet: ${getPointspend(item)}</div>
                                    : <div />
                            }
                        </div>
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        {
                            <div>${(item.amount / 100).toFixed(2)}</div>
                        }
                    </TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    function renderCloverItem(item) {
        return (
            <TableRow key={item.id}>
                <TableCell >
                    <TableCellLayout>{item?.id}</TableCellLayout>
                </TableCell>
                <TableCell >
                    <TableCellLayout>
                        <Label>{format(new Date(item.createdTime), 'dd-MMM-yyyy h:mm a')}</Label>
                    </TableCellLayout>
                </TableCell>
                <TableCell >
                    <TableCellLayout>
                        {
                            item.user.guest ?
                                <div>Guest: {item?.note}</div>
                                :
                                <div style={{ lineBreak: 'anywhere' }}>
                                    <Label>{item?.user?.email || ''}</Label>
                                </div>
                        }
                    </TableCellLayout>
                </TableCell>
                <TableCell className={"table-row-fonts__desc"}>
                    <TableCellLayout>
                        <LineItemList lineItems={item?.lineItems?.elements} />
                    </TableCellLayout>
                </TableCell>
                <TableCell >
                    <TableCellLayout> Clover </TableCellLayout>
                </TableCell>
                <TableCell>
                    <TableCellLayout>${convertStripeAmount(item.total).toFixed(2)}</TableCellLayout>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <div className={"d-flex"}>
                        <div style={{ marginTop: '8px' }}>
                            <FilterSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openPopup}>
                            <span>MEMBER NAME</span>
                            <DownArowSVG />
                        </div>
                        <div className={'memberNameDiv'} onClick={openDatePopup}>
                            <span>Order Date</span>
                            <DownArowSVG />
                        </div>
                    </div>
                    {/* <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    /> */}
                    <div className={"d-flex"}>
                        <div className={"exportbtn"} onClick={openExportPopup}>
                            <DownloadSVG />
                            <span>Export</span>
                            <DownSmallArrow />
                        </div>
                        <div className={"printbtn"} onClick={() => { onGenerateExportClick(2) }}>
                            <PrintIconSVG />
                            <span>Print</span>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: '10px' }}>
                    {/* {dateTags.length !== 0 && (<div style={{ marginTop: '5px', marginRight: '10px', color: '#202020', fontSize: 14, fontWeight: '600' }}>Applied filters:</div>
                    )} */}
                    {visibleTags.length !== 0 && (
                        <TagGroup onDismiss={removeItem} aria-label="Dismiss example" style={{ marginRight: 10 }}>
                            {visibleTags.map((tag, index) => (
                                <Tag
                                    dismissible
                                    dismissIcon={{ "aria-label": "remove" }}
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? firstTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                    {dateTags.length !== 0 && (
                        <TagGroup role="list" aria-label="Dismiss example">
                            {dateTags.map((tag, index) => (
                                <Tag
                                    value={tag.value}
                                    key={tag.value}
                                    ref={index === 0 ? dateTagRef : null}
                                >
                                    {tag.children}
                                </Tag>
                            ))}
                        </TagGroup>
                    )}
                </div>

                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell style={{ width: column.width }} key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredOrderHistory.length > 0 && filteredOrderHistory.map((item) => {
                                if (item.cloverRecord) {
                                    return renderCloverItem(item)
                                }
                                else if (item.metadata.productInfo) {
                                    return renderPosItem(item)
                                }
                                else if (item.metadata && item.metadata.isPaymentFrom && item.metadata.isPaymentFrom == BookingCategory.Paid_Event_RSVP) {
                                    return renderEventsItem(item)
                                } else if (item.metadata && item.metadata.isPaymentFrom && item.metadata.isPaymentFrom == BookingCategory.Vehicle_Booking) {
                                    return renderRentalItem(item)
                                }
                                else {
                                    return (<div></div>)
                                }
                            })}
                        </TableBody>
                        {
                            isLoading || filteredOrderHistory.length > 0 ? <div /> :
                                // <TableBody>
                                <div className="nodatamain">
                                    <div>
                                        <NodataInfoSVG />
                                    </div>
                                    <div className="emptyTitleFont">
                                        Data Table is Empty
                                    </div>
                                    <div className="emprtyDescFont">
                                        Please date range, then click 'GENERATE REPORT' to view, export, and print the data.
                                    </div>
                                </div>
                            // </TableBody>
                        }
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && visibleTags.length < 1 && selectedOption == null && filteredOrderHistory.length > 0 && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
                {popupVisible && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <CustomSearchInput2
                            value={searchMembers}
                            onChange={handleSearchMember}
                            onClick={handleClear}
                        />
                        <div className="popupMemberList">
                            {members
                                .filter(member => member.name.toLowerCase().includes(searchMembers))
                                .map((member) => (
                                    <div key={member.id}>
                                        {/* <Label className="checkLabeldiv">
                                            <input
                                                type="checkbox"
                                                checked={member.selected}
                                                onChange={() => toggleSelect(member.id)}
                                            />
                                            {member.name}
                                        </Label> */}
                                        <Label style={{ marginBottom: '4px' }} className="checkLabeldiv">
                                            <div
                                                onClick={() => toggleSelect(member.id)}
                                                style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                            >
                                                {member.selected ? (
                                                    <CheckBoxChecked />  // Your checked SVG
                                                ) : (
                                                    <CheckBoxUnChecked />  // Your unchecked SVG
                                                )}
                                                <div style={{ marginLeft: '8px' }}>{member.name}</div>
                                            </div>
                                        </Label>
                                    </div>
                                ))}
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelMember}>Cancel</button>
                            <button className="fillButton" onClick={handleApplyMember}>APPLY</button>
                        </div>
                    </div>
                )}
                {popupVisibleExport && (
                    <div className="popupexport" style={{ position: 'absolute', top: popupPosition.y, right: 40 }}>
                        <div>
                            <div className="radioFrame" onClick={() => handleExportChange('CSV')}>
                                {exportSelectedOption === 'CSV' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">CSV</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('PDF')}>
                                {exportSelectedOption === 'PDF' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">PDF</div>
                            </div>
                            <div className="radioFrame" onClick={() => handleExportChange('Excel')}>
                                {exportSelectedOption === 'Excel' ? <RadioSelected /> : <RadioUnSelected />}
                                <div className="radioBtnText">Excel</div>
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelExport}>Cancel</button>
                            <button className="fillButton" onClick={() => { onGenerateExportClick(1) }}>EXPORT</button>
                        </div>
                    </div>
                )}

                {popupVisibleDate && (
                    <div className="popupmain" style={{ position: 'absolute', top: popupPosition.y, left: popupPosition.x }}>
                        <div className="dateFramesContainer">
                            <div style={{ width: '47%' }}>
                                <span>Start Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedStartDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Start Date"
                                />
                            </div>
                            <div style={{ marginLeft: '3px', marginRight: '3px', marginTop: '16px', width: '18px', height: '1px', backgroundColor: '#C1C1C1' }}></div> {/* Add a separate element for the dash line */}
                            <div style={{ width: '47%' }}>
                                <span>End Date</span>
                                <DatePicker
                                    showTimeSelect
                                    selected={selectedEndDate}
                                    contentAfter={<CalanderSVG />}
                                    onSelectDate={(e) => handleDateSelect2(e)}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="End Date"
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '10px' }} className="seperateLine"></div>

                        {["Today", "Yesterday", "This month", "Past month"].map(option => (
                            <div
                                key={option}
                                className={`todayBtn${selectedOption === option ? " selected" : ""}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </div>
                        ))}
                        <div className="seperateLine"></div>
                        <div className="buttonContainer">
                            <button className="borderButton" onClick={handleCancelDateFilter}>Cancel</button>
                            <button className="fillButton" onClick={handleDateApply}>APPLY</button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default ProductOrderHistory;

const ProductList = ({ productInfo, products }) => {
    const calculateTotal = (items) => {
        return items.reduce((total, product) => {
            return total + (product.price * product.quantity);
        }, 0);
    };

    const totalAmount = products && products.length > 0
        ? calculateTotal(products)
        : calculateTotal(productInfo);

    if (products && products.length > 0) {
        return (
            <div>
                {products.map((product, index) => (
                    <div key={index}>
                        <div key={index} style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{product.quantity} x</div>
                                <div style={styles.productName2} >{`${product.productName}`}/</div>
                                <div style={styles.productName}>${(product.price / 100).toFixed(2)}</div>
                            </div>

                        </div>
                    </div>
                ))}
                <div style={styles.productTotal}>Product Total: ${(totalAmount / 100).toFixed(2)}</div>
            </div>
        );
    } else {
        return (
            <div>
                {productInfo.map((product, index) => (
                    <div key={index}>
                        <div key={index} style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{product.quantity} x</div>
                                <div style={styles.productName2} >{`${product.name}`}/</div>
                                <div style={styles.productName}>${(product.price / 100).toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                ))}
                <div style={styles.productTotal}>Total: ${(totalAmount / 100).toFixed(2)}</div>
            </div>
        );
    }
};

const EventInfo = ({ eventInfo }) => {
    if (eventInfo && eventInfo.length > 0) {
        return (
            <div>
                {eventInfo.map((product, index) => (
                    <div key={index}>
                        <div key={index} style={styles.productDetailsContainer}>
                            <div className="d-flex" style={{}}>
                                <div style={styles.productName}>{product.name}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div>
            </div>
        );
    }
};

function mergeAndSort(historyArr, dataArr) {
    // Normalize data array
    const normalizedData = dataArr.map(item => ({
        ...item,
        cloverRecord: true,
        timestamp: item.createdTime // Use createdTime directly
    }));

    // Normalize history array
    const normalizedHistory = historyArr.map(item => ({
        ...item,
        cloverRecord: false,
        timestamp: item.createdAt.seconds * 1000 + Math.floor(item.createdAt.nanoseconds / 1e6)
    }));

    // Merge both arrays
    const mergedArray = [...normalizedData, ...normalizedHistory];

    // Sort by timestamp
    mergedArray.sort((a, b) => a.timestamp - b.timestamp); // Ascending order
    // If you want descending order, use: b.timestamp - a.timestamp

    return mergedArray;
}

const styles = {
    productDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    imageContainer: {
        marginRight: 10,
    },
    productImage: {
        width: 62,
        height: 62,
        borderRadius: 5,
    },
    productName: {
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productName2: {
        marginLeft: '6px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px'
    },
    productTotal: {
        marginTop: '2px',
        color: '#202020',
        fontFamily: 'Nunito Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
    },
    productPrice: {
        fontSize: 12,
        color: '#535353'
    },
    separator: {
        width: '100%',
        borderTop: '1px solid #ccc',
        marginTop: 10,
        marginBottom: 10
    }
};