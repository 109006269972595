import {
    Button,
    Label,
    Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import BookingDataService from "./services/bookingDataService";
import { auth, db, trackException } from "./services/firebase";
//import ImageDialog from "./components/ImageDialog";
import DeleteEventDialog from "./components/DeleteEventDialog";
import CoachForm from "./components/Forms/CoachForm";
import CoachDataService from "./services/coachDataService";
import UserDataService from "./services/userDataService";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "coachType", label: "Coach Type" },
    { columnKey: "description", label: "Description" },
    { columnKey: "startDate", label: "Start Date" },
    { columnKey: "endDate", label: "End Date" },
    { columnKey: "member", label: "Member" },
    { columnKey: "createdBy", label: "Created By" },
];

function Coach() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedCoach, setSelectedCoach] = useState();
    const [allCoach, setAllCoach] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCoach, setFilteredCoach] = useState([]);
    const [allusers, setAllUsers] = useState([]);
    const [coachMembers, setCoachMembers] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [currentuserId, setCurrentuserId] = React.useState('');
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedCoach(selectedItem);
    }

    const fetchUserNameAndCoach = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                const currentuser = userdoc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                //console.log(currentuser[0].id);
                setCurrentuserId(currentuser[0].id);
                //if (userdoc.docs.length > 0) {
                const usersQuery = await UserDataService.getAllUser();
                let allUserarr = usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                allUserarr.sort((a, b) => {
                    const lastNameA = a.lastName || ''; // If lastName is null or undefined, treat it as empty string
                    const lastNameB = b.lastName || ''; // If lastName is null or undefined, treat it as empty string
                    return lastNameA.localeCompare(lastNameB); // Compare the last names alphabetically
                });
                setAllUsers(allUserarr);

                const CoachsQuery = await BookingDataService.getAllCoach();
                let allCoaches = CoachsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                allCoaches.sort((a, b) => {
                    const lastNameA = a.lastName || ''; // If lastName is null or undefined, treat it as empty string
                    const lastNameB = b.lastName || ''; // If lastName is null or undefined, treat it as empty string
                    return lastNameA.localeCompare(lastNameB); // Compare the last names alphabetically
                });
                setAllCoach(allCoaches);
                setFilteredCoach(allCoaches);
                const CoachsMemberQuery = await CoachDataService.getAllCoachMember();
                setCoachMembers(CoachsMemberQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                //console.log(allevents);
            } else {
                console.log('no access');
                // }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndCoach();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedCoach(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteCoach = async () => {
        BookingDataService.deleteBooking(selectedCoach.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndCoach();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allCoach.filter(event => {
            if (typeof event.coachType === 'object' && event.coachType.value && event.coachType.label) {
                return (
                    event.coachType.value.toLowerCase().includes(lowerSearch) ||
                    event.coachType.label.toLowerCase().includes(lowerSearch) ||
                    event.description.toLowerCase().includes(lowerSearch)
                );
            } else {
                return (
                    event.coachType.toLowerCase().includes(lowerSearch) ||
                    event.description.toLowerCase().includes(lowerSearch)
                );
            }
        });
        setFilteredCoach(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Coach Booking'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredCoach.length > 0 && filteredCoach.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.coachType.label ? item.coachType.label : item.coachType}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.description}>{item.description.substring(0, 150)}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>{moment(item.startDate).format('Do MMMM YYYY hh mm A')}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.endDate).format('Do MMMM YYYY hh mm A')}

                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Label>{allusers.filter(t => t.id == item.memberId)[0]?.firstName} &nbsp;
                                            {allusers.filter(t => t.id == item.memberId)[0]?.lastName}</Label>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Label>{allusers.filter(t => t.id == item.createdBy)[0]?.firstName} &nbsp;
                                            {allusers.filter(t => t.id == item.createdBy)[0]?.lastName}</Label>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <CoachForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedCoach={selectedCoach}
                updateGrid={fetchUserNameAndCoach} createdBy={currentuserId} coachMembers={coachMembers} users={allusers}></CoachForm>
            {/*<ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedCoach}></ImageDialog> */}
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteCoach} text={'Coach'} />

        </Layout>
    )
}

export default Coach;